// 信托
<template>
  <div class="financial-trust">
    <div class="trust-title" v-html="info.name"> </div>
    <div class="trust-content">
      <div class="content-banner">
        <div class="banner">
          <el-image v-if="info.picture" class="banner-image" :src="$base+info.picture"></el-image>
        </div>

        <div class="banner-title" v-html="info.describe"> </div>
      </div>
      <p class="trust-info" v-html="info.content"></p>
    </div>
  </div>
</template>

<script>
import { getCore } from '@/api'
export default {
  data () {
    return {
      info: {}
    };
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      let { data } = await getCore('xt')
      this.info = data[0]
    }
  },
  components: {

  },
};
</script>

<style scoped lang="less">
/deep/ .trust-title {
  margin-bottom: 27px;
  padding-left: 30px;
  h1 {
    font-size: 24px;
    margin-bottom: 12px;
  }
}
.trust-content {
  height: 330px;
  background-color: #fff;
  margin-top: 120px;
  padding: 0 28px;
  position: relative;
  .content-banner {
    padding: 60px 0;
    display: flex;
    > div {
      width: 50%;
    }
    .banner-image {
      position: absolute;
      top: -20%;
      max-width: 393px;
    }
    /deep/ .banner-title {
      width: 40%;
      float: right;
      h1 {
        font-size: 23px;
        margin-bottom: 10px;
      }
      p {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
      }
    }
  }
  .trust-info {
    line-height: 25px;
    padding: 0 20px;
  }
}
</style>
